<template>
    <div class="lg:grid lg:grid-cols-12 lg:gap-x-5">
        <aside class="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
            <nav class="space-y-1">
                <a v-for="item in items" :key="item.name" :href="item.href" :class="[item.current ? 'bg-gray-50 text-orange-600 hover:bg-white' : 'text-gray-900 hover:text-gray-900 hover:bg-gray-50', 'group rounded-md px-3 py-2 flex items-center text-sm font-medium']" :aria-current="item.current ? 'page' : undefined">
                    <component :is="item.icon" :class="[item.current ? 'text-orange-500' : 'text-gray-400 group-hover:text-gray-500', 'flex-shrink-0 -ml-1 mr-3 h-6 w-6']" aria-hidden="true" />
                    <span class="truncate">
                        {{ item.name }}
                    </span>
                </a>
            </nav>
        </aside>

        <div class="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
            <slot />
        </div>
    </div>
</template>

<script>
    import {
        Disclosure,
        DisclosureButton,
        DisclosurePanel,
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
        RadioGroup,
        RadioGroupDescription,
        RadioGroupLabel,
        RadioGroupOption,
        Switch,
        SwitchGroup,
        SwitchLabel,
    } from '@headlessui/vue';
    import { QuestionMarkCircleIcon, MagnifyingGlassIcon as SearchIcon } from '@heroicons/vue/24/solid';
    import {
        BellIcon,
        UserIcon,
        UsersIcon,
        UserGroupIcon,
        CurrencyDollarIcon,
        AdjustmentsHorizontalIcon as AdjustmentsIcon,
        DevicePhoneMobileIcon as DeviceMobileIcon,
        Bars3Icon as MenuIcon,
        LockClosedIcon,
        CogIcon,
        TagIcon,
        WrenchScrewdriverIcon,
        XMarkIcon as XIcon,
    } from '@heroicons/vue/24/outline';

    export default {
        components: {
            Disclosure,
            DisclosureButton,
            DisclosurePanel,
            Menu,
            MenuButton,
            MenuItem,
            MenuItems,
            RadioGroup,
            RadioGroupDescription,
            RadioGroupLabel,
            RadioGroupOption,
            Switch,
            SwitchGroup,
            SwitchLabel,
            BellIcon,
            MenuIcon,
            QuestionMarkCircleIcon,
            SearchIcon,
            XIcon,
            CogIcon,
            TagIcon,
            UserIcon,
            UsersIcon,
            UserGroupIcon,
            LockClosedIcon,
            AdjustmentsIcon,
            DeviceMobileIcon,
            CurrencyDollarIcon,
            WrenchScrewdriverIcon,
        },
        props: {
            items: {
                type: Array,
                required: true,
            },
        },
    };
</script>
