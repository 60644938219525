<template>
    <div>
        <slot :on-print="onPrint" :has-printer="hasPrinter" />
    </div>
</template>

<script>
    export default {
        props: {
            labels: {
                type: Array,
                required: true,
            },
        },

        data() {
            return {
                hasPrinter: false,
            };
        },

        mounted() {
            BrowserPrint.getDefaultDevice('printer', (device) => {
                if (device) {
                    this.hasPrinter = true;
                }
            });
        },

        methods: {
            onPrint() {
                const zpl = `^XA
~TA000
~JSN
^LT0
^MNW
^MTT
^PON
^PMN
^LH0,0
^JMA
^PR6,6
~SD30
^JUS
^LRN
^CI27
^PA0,1,1,0
^XZ
^XA
^MMT
^PW607
^LL440
^LS0
^FO363,130^GFA,1673,3024,24,:Z64:eJx1lr9v20YUx9+FulpIbZ4GEfAgWik8dkhHDYVJAfVYgAZ4gAcRKNB/okAHM9XgqdCf0I5Fl/wHNd00RbdokLYKOFRDg3Swii6sE/D67gfJo+qcbfL8/PHTu/fjS/bh4RW+xx69ftge8P/bhvjD5P2++WmFF5JlcddMs5n+q6xy1/yxlIoHwnnS+bz696dS3v9grT9Ktcz/0zWuld5xtV0vDcNkd9UfpzyalZhrfeKGLM3V2rOMZ2Yl5mrtUm4tLyKX53xa+9cfldb8Xy90GDVfGTPZ3N6uN+vNMlwXwUbtjN2T27l2G1leWp5PiXUem6PU/M/PurxNT6ZqgGGHWRzqA9R8fqD5seWfWz4l1PWfUlM/9tpbOHxZecLwBdUVWAYYP94SUhj+7lrzO8Pvvjq1PKeu/ySZWv9yoXkxlgXy4qOt5TN6lGVUBR+HszCe8ppn7HnlScFkHpVM4K7xTxOinEMQ05jaAiPvs/La8ILhzfIbekiXdFOE6zzI8TQb0z+sYv1TwRaa/0bxO5sfCtM4QMcpBEDxY6z/yodt/tNYsBJOHrNiXPOzI3JOMPCjBEa9EI9h+IOKeVtPYDwCTiDKozqelGLGMX68DgHPYP17FWNCeFXO5vkIKqh5sqJ0mdAVUApDshpgGWp+Ud7flMAYHHvlpOG5ST/0KAyCWJXB9oMeGJH7fr/P8rLhMfe4pnA4Gox65DK080zktZRv5Tz3T4598JDPW/9TTqA3HPTwNI1gSCxvIa9zfzTxc1bV/QkZ9nyxptAbXFLgTf/DlayiHIvgH0/Ym7b/QbUOpLTXw4s7XxG2PlT+oQ/vmDNfgAkfwexw1IPZkTrJrOXPoPTPfK8au/OLCR9CAkNKeODqA5Plp092cMbujETUPF0vB3GMwegp4Db9it9NvjiFykzZlU2natBkkBCwZeA2/aoA5ZPSm8sbid9NOlUBEnJJz1Xk9AKnGOolS6/UJy29v3dR1dh5QlITOblIgtThjSRWu7nYRWVjD35XurPKVtNnxTJcNnZDl1+XX86FiHaNnRpxw8klcRzEjd0zfFn+6uUiEo2dGNlMLj8jAwhbHgwvJr88mvSjvLWb4GPM6mAQtGaj0OUfnxSiwtK1y2Q+htslXw4du0nOB7C9l11e+6dKRhOXN5ViSnbLicur+C+P1GMgGTh2z+qtUjmX1/psS5Ds8/aRsXP59ab+Kvb4Fwa/EcLluZX+BOe3y//2GNvnDURFh8+yz80unO7xC/3UjsbzPf9UjVXAA7LP41ip27wbv34C4y14uXyYlx2e42SlapIp3/P/nRorxZeOHfMfZpdqks+b6VXrQE+K6otXXV75T1T85zx17EzxQt3/lJVjD9ebYFOoPnrZqI9OCyqQGkNPPenz1q6Lq89x0Y4vqLhr/q1sxl2nf6bjJtlF+/qgj1td6ThcOdHpSU3cSh7aAzAlOxqT76RzAGz/pXnp4St8A3L5fw1/hXG1B8DWuTB5103a8KiC/5g6RUqEiiY9qP5G9FUd2te5SL0BCbNbODz6PDdpUQXgLv+q5u+cBKH6fGtUM9jcbhr9VPzCRB3Juctj36R2Rzr+K2ay+GEFnfhnxEIzyFr9HKNwWqdnELn5cZqSuvlxmsxz+DZkXC3P5PeO/dFDSHf132N/aP0HdIpn4Q==:2C00
^FT58,327^BQN,2,7
^FH\\^FDLA,{{ url }}^FS
^FPH,8^FT0,356^A@N,33,40,TT0003M_^FB347,1,17,C^FH\\^CI28^FD{{ identifier }}^FS^CI27
^PQ1,0,1,Y
^XZ
`;

                BrowserPrint.getDefaultDevice('printer', (device) => {
                    const printer = new Zebra.Printer(device);
                    console.log(printer);

                    for (const labelData of this.labels) {
                        const variables = {
                            url: `https://tuw.app/a/${labelData.uuid}`,
                            identifier: labelData.id,
                        };

                        const label = zpl.replace(/{{ ?(\w+) ?}}/g, (match, g1) => {
                            return variables[g1];
                        });

                        printer.isPrinterReady().then(() => {
                            printer.send(label);
                        }).catch((error) => {
                            console.error(error);
                        });
                    }
                }, (error) => {
                    console.error(error);
                });
            },
        },
    };
</script>
