<template>
    <div>
        <slot />
    </div>
</template>

<script>
    import { Sortable, Plugins } from '@shopify/draggable';
    import axios from 'axios';

    export default {
        props: {
            endpoint: {
                type: String,
                required: true,
            },
            csrfToken: {
                type: String,
                required: true,
            },
        },

        mounted() {
            const container = this.$el.querySelector('[data-container]');
            const sortable = new Sortable(container, {
                draggable: '[data-sortable]',
                handle: '[data-handle]',
                mirror: {
                    constrainDimensions: true,
                    xAxis: false,
                    yAxis: true,
                },
                swapAnimation: {
                    duration: 200,
                    easingFunction: 'ease-in-out',
                },
                plugins: [Plugins.SwapAnimation],
            });

            sortable.on('sortable:stop', () => {
                const nodes = Array.prototype.slice.call(
                    this.$el.querySelectorAll('[data-sortable]'),
                );

                const ids = nodes.map((node) => node.dataset.sortable);
                this.submit(ids);
            });
        },

        methods: {
            async submit(ids) {
                const data = await axios.post(this.endpoint, {
                    ids,
                }, {
                    headers: {
                        'X-CSRFToken': this.csrfToken,
                    },
                });

                console.log(data);
            },
        },
    };
</script>

<style lang="postcss">
    .draggable-mirror {
        @apply hidden;
    }
</style>
