<template>
    <div class="flex items-center">
        <chevron class="flex-shrink-0 h-5 w-5 text-gray-400" />
        <component
            :is="href ? 'a' : 'span'"
            :href="href"
            class="ml-4 text-sm font-medium text-gray-500"
            :class="{ 'hover:text-gray-700': href }"
        >
            <slot />
        </component>
    </div>
</template>

<script>
    import Chevron from '../../img/chevron.svg';

    export default {
        components: {
            Chevron,
        },

        props: {
            href: {
                type: String,
                default: null,
            },
        },
    };
</script>
