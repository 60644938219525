<template>
    <Disclosure v-slot="{ open }" as="nav" class="bg-white border-b border-gray-200">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="flex justify-between h-16">
                <div class="flex">
                    <a class="flex-shrink-0 flex items-center" href="/">
                        <img class="block h-8 w-auto rounded-sm overflow-hidden" :src="logo" />
                    </a>
                    <div class="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                        <a v-for="item in navigation" :key="item.name" :href="item.href" :class="[item.current ? 'border-indigo-500 text-gray-900' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700', 'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium']" :aria-current="item.current ? 'page' : undefined">
                            {{ item.name }}
                        </a>
                    </div>
                </div>
                <div class="hidden sm:ml-6 sm:flex sm:items-center">
                    <Menu v-if="notifications !== null && notifications.length > 0" as="div" class="relative">
                        <MenuButton
                            type="button"
                            class="relative rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            @click="onOpenNotifications"
                        >
                            <span class="absolute -inset-1.5" />
                            <span class="sr-only">View notifications</span>
                            <BellIcon class="h-6 w-6" aria-hidden="true" />

                            <span
                                v-if="unreadNotificationsCount > 0"
                                class="flex items-center justify-center absolute -top-1.5 -right-1.5 h-5 min-w-[1.25rem] bg-red-600 rounded-full text-white text-xs font-bold"
                            >
                                <span class="block whitespace-nowrap px-1">{{ unreadNotificationsCount }}</span>
                            </span>
                        </MenuButton>

                        <transition enter-active-class="transition ease-out duration-200" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                            <MenuItems class="origin-top-right absolute right-0 mt-2 w-72 z-50 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <MenuItem v-for="item in notifications" :key="item.id">
                                    <p class="group flex items-start px-4 py-2 text-sm text-black">
                                        <InformationCircleIcon
                                            class="mr-3 mt-2 h-5 w-5 flex-shrink-0"
                                            :class="item.read ? 'text-gray-600' : 'text-yellow-600'"
                                            aria-hidden="true"
                                        />
                                        {{ item.message }}
                                    </p>
                                </MenuItem>
                            </MenuItems>
                        </transition>
                    </Menu>

                    <Menu as="div" class="relative ml-3">
                        <div>
                            <MenuButton class="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                <span class="sr-only">Open user menu</span>
                                <span class="inline-flex items-center justify-center h-8 w-8 rounded-full bg-gray-500">
                                    <span class="text-sm font-medium leading-none text-white">{{ initials }}</span>
                                </span>
                            </MenuButton>
                        </div>
                        <transition enter-active-class="transition ease-out duration-200" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                            <MenuItems class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <MenuItem v-for="item in userNavigation" :key="item.name" v-slot="{ active }">
                                    <a :href="item.href" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">
                                        {{ item.name }}
                                    </a>
                                </MenuItem>
                            </MenuItems>
                        </transition>
                    </Menu>
                </div>
                <div class="-mr-2 flex items-center sm:hidden">
                    <!-- Mobile menu button -->
                    <DisclosureButton class="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <span class="sr-only">Open main menu</span>
                        <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
                        <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
                    </DisclosureButton>
                </div>
            </div>
        </div>

        <DisclosurePanel class="sm:hidden">
            <div class="pt-2 pb-3 space-y-1">
                <DisclosureButton v-for="item in navigation" :key="item.name" as="a" :href="item.href" :class="[item.current ? 'bg-indigo-50 border-indigo-500 text-indigo-700' : 'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800', 'block pl-3 pr-4 py-2 border-l-4 text-base font-medium']" :aria-current="item.current ? 'page' : undefined">
                    {{ item.name }}
                </DisclosureButton>
            </div>
            <div class="pt-4 pb-3 border-t border-gray-200">
                <div class="flex items-center px-4">
                    <div class="flex-shrink-0">
                        <span class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-500">
                            <span class="font-medium leading-none text-white">{{ initials }}</span>
                        </span>
                    </div>
                    <div class="ml-3">
                        <div class="text-base font-medium text-gray-800">{{ user.name }}</div>
                        <div class="text-sm font-medium text-gray-500">{{ user.email }}</div>
                    </div>
                </div>
                <div class="mt-3 space-y-1">
                    <DisclosureButton v-for="item in userNavigation" :key="item.name" as="a" :href="item.href" class="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100">
                        {{ item.name }}
                    </DisclosureButton>
                </div>
            </div>
        </DisclosurePanel>
    </Disclosure>
</template>


<script>
    import {
        Disclosure,
        DisclosureButton,
        DisclosurePanel,
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
    } from '@headlessui/vue';
    import {
        Bars3Icon as MenuIcon,
        XMarkIcon as XIcon,
        BellIcon,
        InformationCircleIcon,
    } from '@heroicons/vue/24/outline';
    import Axios from 'axios';

    const userNavigation = [
        { name: 'Your Profile', href: '/settings/profile/' },
        { name: 'Settings', href: '/settings/' },
        { name: 'Sign out', href: '/logout/' },
    ];

    export default {
        components: {
            Disclosure,
            DisclosureButton,
            DisclosurePanel,
            Menu,
            MenuButton,
            MenuItem,
            MenuItems,
            MenuIcon,
            BellIcon,
            InformationCircleIcon,
            XIcon,
        },

        inject: ['user', 'navigation', 'logo'],

        props: {
            notifications: {
                type: [Array, null],
                default: null,
            },
        },

        emits: ['clear-notifications'],

        setup() {
            return {
                userNavigation,
            };
        },

        computed: {
            initials() {
                return this.user.name.split(' ').map((n) => n[0]).join('').toUpperCase();
            },
            unreadNotificationsCount() {
                if (!this.notifications) {
                    return 0;
                }

                return this.notifications.filter((n) => !n.read).length;
            },
        },

        methods: {
            async onOpenNotifications() {
                await Axios.post('/notifications/clear/', {}, {
                    headers: {
                        // Add Django CSRF
                        'X-CSRFToken': document.querySelector('input[name="csrfmiddlewaretoken"]').value,
                    },
                });

                setTimeout(() => {
                    this.$emit('clear-notifications');
                }, 2000);
            },
        },
    };
</script>
