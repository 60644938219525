<template>
    <div>
        <label
            :for="id"
            :class="{
                'block text-sm font-medium text-gray-700': !hideLabel,
                'sr-only': hideLabel,
            }"
        >{{ label }}</label>
        <div
            class="mt-1"
            :class="{ 'relative rounded-md shadow-sm': errors.length > 0 }"
        >
            <select
                :id="id"
                :name="name"
                class="rounded-md"
                :class="[inputClass, {
                    'block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm': errors.length === 0,
                    'block w-full pr-16 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm': errors.length > 0,
                }]"
                :required="required"
                :multiple="multiple"
                :aria-invalid="errors.length > 0 ? 'true' : 'false'"
                :aria-describedby="errors.length > 0 ? `${id}-error` : null"
                v-bind="$attrs"
            >
                <slot />
            </select>

            <div v-if="errors.length > 0" class="absolute inset-y-0 right-0 pr-8 flex items-center pointer-events-none">
                <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
            </div>
        </div>

        <p v-if="help" class="mt-2 text-sm text-gray-500">{{ help }}</p>

        <div v-if="errors.length > 0" :id="`${id}-error`" class="mt-2 text-sm text-red-600">
            <ul>
                <li v-for="(error, index) in errors" :key="`error-${index}`">{{ error }}</li>
            </ul>
        </div>
    </div>
</template>

<script>
    import { ExclamationCircleIcon } from '@heroicons/vue/24/solid';

    export default {
        components: {
            ExclamationCircleIcon,
        },

        props: {
            id: {
                type: String,
                required: true,
            },
            name: {
                type: String,
                required: true,
            },
            multiple: {
                type: Boolean,
                default: false,
            },
            required: {
                type: Boolean,
                default: false,
            },
            label: {
                type: String,
                required: true,
            },
            help: {
                type: String,
                default: null,
            },
            hideLabel: {
                type: Boolean,
                default: false,
            },
            inputClass: {
                type: [Array, Object, String],
                default: null,
            },
            errors: {
                type: Array,
                default: () => ([]),
            },
            value: {
                type: [String, Number],
                default: '',
            },
        },
    };
</script>
