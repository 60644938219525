<template>
    <div>
        <label
            v-if="label"
            :for="id"
            :class="{
                'block text-sm font-medium text-gray-700': !hideLabel,
                'sr-only': hideLabel,
            }"
        >{{ label }}</label>
        <div
            class="mt-1"
            :class="{ 'relative rounded-md shadow-sm': errors.length > 0 && type !== 'file' }"
        >
            <input
                :id="id"
                :type="type"
                :class="[inputClass, {
                    'rounded-md': type !== 'file',
                    'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300': errors.length === 0 && type !== 'file',
                    'block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm': errors.length > 0 && type !== 'file',
                }]"
                v-bind="$attrs"
                :aria-invalid="errors.length > 0 ? 'true' : 'false'"
                :aria-describedby="errors.length > 0 ? `${id}-error` : null"
            />

            <div v-if="errors.length > 0" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
            </div>
        </div>

        <div v-if="help" class="mt-2 text-sm text-gray-500" v-html="help" />

        <div v-if="errors.length > 0" :id="`${id}-error`" class="mt-2 text-sm text-red-600">
            <ul>
                <li v-for="(error, index) in errors" :key="`error-${index}`">{{ error }}</li>
            </ul>
        </div>
    </div>
</template>

<script>
    import { ExclamationCircleIcon } from '@heroicons/vue/24/solid';

    export default {
        components: {
            ExclamationCircleIcon,
        },

        props: {
            id: {
                type: String,
                required: true,
            },
            type: {
                type: String,
                default: 'text',
            },
            label: {
                type: String,
                required: true,
            },
            help: {
                type: String,
                default: null,
            },
            hideLabel: {
                type: Boolean,
                default: false,
            },
            inputClass: {
                type: [Array, Object, String],
                default: null,
            },
            errors: {
                type: Array,
                default: () => ([]),
            },
        },
    };
</script>
