<template>
    <section>
        <slot :on-add-value="onAddValue" :type="type" />
    </section>
</template>

<script>
    import { createApp, h } from 'vue';
    import FormInput from './forms/FormInput.vue';
    import FormSelect from './forms/FormSelect.vue';
    import FormCheckboxes from './forms/FormCheckboxes.vue';

    export default {
        props: {
            newValueTemplate: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                type: null,
            };
        },

        mounted() {
            this.$el.querySelector('#id_type')?.addEventListener('change', this.onTypeChange);
            this.onTypeChange();
        },

        beforeUnmount() {
            this.$el.querySelector('#id_type')?.removeEventListener('change', this.onTypeChange);
        },

        methods: {
            onTypeChange() {
                const el = this.$el.querySelector('#id_type');
                if (el) {
                    this.type = el.value;
                }
            },

            onAddValue() {
                const valuesList = this.$el.querySelector('#values-list');
                const totalForms = this.$el.querySelector('#id_values-TOTAL_FORMS');

                const template = {
                    template: this.newValueTemplate.replace(/__prefix__/g, totalForms.value),
                };

                const app = createApp({
                    render() {
                        return h(template);
                    },
                });

                app.component('FormInput', FormInput);
                app.component('FormSelect', FormSelect);
                app.component('FormCheckboxes', FormCheckboxes);

                const el = document.createElement('li');
                el.className = 'px-6 py-4';

                app.mount(el);
                valuesList.appendChild(el);
                totalForms.value = parseInt(totalForms.value) + 1;
            },
        },
    };
</script>
