import '../css/style.pcss';
import 'v-calendar/style.css';
import 'vite/modulepreload-polyfill';

import { createApp } from 'vue';

import VCalendar from 'v-calendar';

import Approot from './Approot.vue';
import Settings from './Settings.vue';
import OptionValues from './OptionValues.vue';
import Crumb from './components/Crumb.vue';
import PrintLabels from './components/PrintLabels.vue';
import SortableTable from './components/SortableTable.vue';
import DashboardForm from './components/DashboardForm.vue';
import Chevron from '../img/chevron.svg';

import FormInput from './forms/FormInput.vue';
import FormTextarea from './forms/FormTextarea.vue';
import FormSelect from './forms/FormSelect.vue';
import FormCheckboxes from './forms/FormCheckboxes.vue';

import { EyeIcon } from '@heroicons/vue/24/solid';

const app = createApp({
    components: {
        Approot,
        Settings,
        OptionValues,
        Crumb,
        PrintLabels,
        DashboardForm,
        Chevron,
        EyeIcon,
    },

    mounted() {
        if (typeof $ !== 'undefined') {
            $(() => {
                $('input.address').each(function() {
                    const self = $(this);
                    const cmps = $(`#${self.attr('name')}_components`);
                    const fmtd = $(`input[name="${self.attr('name')}_formatted"]`);
                    self.geocomplete({
                        details: cmps,
                        detailsAttribute: 'data-geo',
                    }).change(() => {
                        if (self.val() !== fmtd.val()) {
                            const cmpNames = [
                                'country',
                                'country_code',
                                'locality',
                                'postal_code',
                                'postal_town',
                                'route',
                                'street_number',
                                'state',
                                'state_code',
                                'formatted',
                                'latitude',
                                'longitude',
                            ];

                            for (let ii = 0; ii < cmpNames.length; ++ii) {
                                $(`input[name="${self.attr('name')}_${cmpNames[ii]}"]`).val('');
                            }
                        }
                    });
                });
            });
        }
    },
});

app.use(VCalendar, {});

app.component('FormInput', FormInput);
app.component('FormTextarea', FormTextarea);
app.component('FormSelect', FormSelect);
app.component('FormCheckboxes', FormCheckboxes);
app.component('SortableTable', SortableTable);

app.mount('#app');
