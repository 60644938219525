<template>
    <div class="min-h-full">
        <Navbar :notifications="notificationsData" @clear-notifications="onClearNotifications" />

        <div class="py-10">
            <header>
                <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <slot name="heading" />
                </div>
            </header>
            <main>
                <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
                    <div class="px-4 py-8 sm:px-0">
                        <slot />
                    </div>
                </div>
            </main>
        </div>
    </div>
</template>

<script>
    import cloneDeep from 'lodash/cloneDeep';
    import Navbar from './components/Navbar.vue';

    export default {
        components: {
            Navbar,
        },

        provide() {
            return {
                user: this.user,
                logo: this.logo,
                navigation: this.navigation,
            };
        },

        props: {
            title: {
                type: String,
                required: true,
            },
            navigation: {
                type: Array,
                required: true,
            },
            notifications: {
                type: [Array, null],
                default: null,
            },
            user: {
                type: Object,
                required: true,
            },
            logo: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                notificationsData: cloneDeep(this.notifications),
            };
        },

        methods: {
            onClearNotifications() {
                this.notificationsData = this.notificationsData.map((notification) => ({
                    ...notification,
                    read: true,
                }));
            },
        },
    };
</script>
