<template>
    <form :action="action" method="get">
        <v-date-picker v-model.range="range" mode="date">
            <template #default="{ togglePopover }">
                <button
                    type="button"
                    class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    @click.prevent="togglePopover"
                >
                    {{ displayDate }}
                </button>
            </template>
        </v-date-picker>

        <input type="hidden" name="start_date" :value="range.start.toISOString()" />
        <input type="hidden" name="end_date" :value="range.end.toISOString()" />
    </form>
</template>

<script>
    import { DateTime } from 'luxon';

    export default {
        props: {
            start: {
                type: String,
                required: true,
            },
            end: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                range: {
                    start: new Date(this.start),
                    end: new Date(this.end),
                },
            };
        },

        computed: {
            action() {
                return window.location.pathname;
            },
            displayDate() {
                const startDate = DateTime.fromJSDate(this.range.start).toLocaleString(DateTime.DATE_SHORT);
                const endDate = DateTime.fromJSDate(this.range.end).toLocaleString(DateTime.DATE_SHORT);

                return `${startDate} - ${endDate}`;
            },
        },

        watch: {
            range: {
                deep: true,
                handler() {
                    this.$nextTick(() => {
                        this.$el.submit();
                    });
                },
            },
        },
    };
</script>
