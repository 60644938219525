<template>
    <fieldset>
        <legend class="text-lg font-medium text-gray-900">{{ label }}</legend>
        <div class="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200">
            <slot />
        </div>

        <p v-if="help" class="mt-2 text-sm text-gray-500">{{ help }}</p>

        <div v-if="errors.length > 0" :id="`${id}-error`" class="mt-2 text-sm text-red-600">
            <ul>
                <li v-for="(error, index) in errors" :key="`error-${index}`">{{ error }}</li>
            </ul>
        </div>
    </fieldset>
</template>

<script>
    export default {
        props: {
            id: {
                type: String,
                required: true,
            },
            name: {
                type: String,
                required: true,
            },
            required: {
                type: Boolean,
                default: false,
            },
            label: {
                type: String,
                required: true,
            },
            help: {
                type: String,
                default: null,
            },
            inputClass: {
                type: [Array, Object, String],
                default: null,
            },
            errors: {
                type: Array,
                default: () => ([]),
            },
            value: {
                type: [String, Number],
                default: '',
            },
        },
    };
</script>
